<template>
  <div>
    <a-modal
      v-model="visibleShow"
      :title="$t('打印配料表')"
      :okText="$t('打印')"
      @ok="printPickList"
    >
      <IngredientsList
        :isPrint="true"
        :skuCode="currentSku.skuCode"
        :propertiesInfo="currentSku.properties"
        :nutritionList="currentSku.properties?.nutritionList"
      />
    </a-modal>
  </div>
</template>
<script>
import IngredientsList from '@component/ingredientsList'
import { getLodop } from '@/assets/LodopFuncs.js'
export default {
  name: 'PrintInfo',
  components: { IngredientsList },
  data() {
    return { visibleShow: false }
  },
  props: {
    currentSku: {},
  },
  methods: {
    open() {
      this.visibleShow = true
    },
    close() {
      this.visibleShow = false
    },
    printPickList() {
      let ingredientsList = document.getElementById('ingredients-list')
      var LODOP = getLodop()
      LODOP.SET_PRINT_PAGESIZE(0, 500, 700, '')
      LODOP.SET_PRINT_MODE('PRINT_PAGE_PERCENT', 'Full-Page')
      LODOP.SET_PRINT_STYLEA(0, 'FontName', '黑体')
      LODOP.ADD_PRINT_HTM('2%', '2%', '98%', '98%', ingredientsList.innerHTML)
      // LODOP.ADD_PRINT_BARCODE(10, 145, 175, 50, 'EAN128A', this.currentSku.skuCode)
      // LODOP.SET_PRINT_STYLEA(0, 'ShowBarText', 0)
      // LODOP.SET_PRINT_STYLEA(0, 'GroundColor', '#ffffff')
      let printList =
        localStorage.getItem('printList') && JSON.parse(localStorage.getItem('printList'))
      if (printList) {
        printList.forEach((item) => {
          if (item.key == 1) {
            //1 拣货单
            LODOP.SET_PRINTER_INDEX(item.print)
          }
        })
      }
      let isPREVIEW = localStorage.getItem('isPREVIEW')
      if (isPREVIEW) {
        LODOP.PREVIEW()
      } else {
        LODOP.PRINT()
      }
      this.$emit('cancel', false)
    },
  },
}
</script>

<style lang="scss" scoped>
td {
  vertical-align: middle;
}

.box {
  width: 100%;

  //   display: flex;
  //   justify-content: space-between;
  .minibox {
    width: 250px;
    text-align: left;
    display: inline-block;
  }
}

.tableFa {
  td {
    border: 1px solid gray;
    text-align: center;
    vertical-align: middle;
  }
}

.titleBox {
  div {
    width: 33%;
    // float: left;
  }

  .titleCenter {
    text-align: center;
  }
}
</style>
